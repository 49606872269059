
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Layout from './Layout';
import './app.scss';
import Home from './components/home/Home';
import Services from './components/services/Services';
import Faq from './components/faq/Faq';
import Contact from './components/contact/Contact';
import Blog from './components/blog/Blog';
import About from './components/about/About';


function App() {
  return (
    <div className="app">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/faq" element={<Faq />} />
            <Route path="/services" element={<Services />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;



import { Link } from "react-router-dom"
import "./header.scss"
import NavItem from "./components/NavItem"

const props = [
   {
      name: "Home",
      path: "/",
      items: [
         {
            name: "somethink",
            path: "",
         },
         {
            name: "somethink",
            path: "",
         },
         {
            name: "somethink",
            path: "",
         },
      ],
   },
   {
      name: "About us",
      path: "/about",
      items: [
         {
            name: "somethink",
            path: "",
         },
         {
            name: "somethink",
            path: "",
         },
         {
            name: "somethink",
            path: "",
         },
      ],
   },
   {
      name: "Services",
      path: "/services",
      items: [
         {
            name: "somethink",
            path: "",
         },
         {
            name: "somethink",
            path: "",
         },
         {
            name: "somethink",
            path: "",
         },
      ],
   },
   {
      name: "Contacts",
      path: "/contact",
      items: [
         {
            name: "somethink",
            path: "",
         },
         {
            name: "somethink",
            path: "",
         },
         {
            name: "somethink",
            path: "",
         },
      ],
   },
   {
      name: "Blog",
      path: "/blog",
      items: [
         {
            name: "somethink",
            path: "",
         },
         {
            name: "somethink",
            path: "",
         },
         {
            name: "somethink",
            path: "",
         },
      ],
   },
   {
      name: "FAQ",
      path: "/faq",
      items: [
         {
            name: "somethink",
            path: "",
         },
         {
            name: "somethink",
            path: "",
         },
         {
            name: "somethink",
            path: "",
         },
      ],
   },
]

const Header = () => {
   return (
      <>
         <div className="header__holder"></div>
         <div className='header'>
            <div className="header__wrapper">
               <Link to="/" className="header__logo">
                  <img src="/gold-cut.png" />
               </Link>
               <div className="header__row">
                  <nav className="header__nav nav">
                     {
                        props.map((item) => <NavItem key={item.name} name={item.name} path={item.path} items={item.items} />)
                     }
                  </nav>
                  <div className="header__lang lang">
                     <div className="bt"><button className="lang__btn">Languages</button></div>
                     <div className="lang__content">
                        <button ><span>English</span><img src="/us-flag.png" /></button>
                        <button ><span>Russian</span><img src="/rus-flag.png" /></button>
                        <button ><span>Polish</span><img src="/pl-flag.png" /></button>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </>
   )
}

export default Header


import "./faq.scss"

const Faq = () => {
   return (
      <div className='faq'>Faq</div>
   )
}

export default Faq
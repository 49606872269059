

import "./home.scss"

const Home = () => {
   return (
      <div className='home'>Home</div>
   )
}

export default Home
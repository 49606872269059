

import "./services.scss"

const Services = () => {
   return (
      <div className='services'>Services</div>
   )
}

export default Services

import { Link } from "react-router-dom"
import { IoIosArrowDown } from "react-icons/io";
import "../header.scss"

const NavItem = ({name, path, items}) => {
   return (
      <Link to={path} className="nav__item">
         <div className="nav__btn">{name}<IoIosArrowDown /></div>
         <div className="nav__content">
            {
               items.map((item)=><button key={item.name} >{item.name}</button>)
            }
         </div>
      </Link>
   )
}

export default NavItem


import "./contact.scss"

const Contact = () => {
   return (
      <div className='contact'>Contact</div>
   )
}

export default Contact


import "./blog.scss"

const Blog = () => {
   return (
      <div className='blog'>Blog</div>
   )
}

export default Blog